<template>
  <div>
    <k-crud-page>
      <template #condition>
        <k-tree-select
          ref="tree"
          v-model="orgCodes"
          class="my-1"
          placeholder="请选择机构信息"
          tree-key="uuid"
          label="机构信息"
          :items="items"
        />
        <k-primary-button
          class="my-1 mr-4"
          text="搜索"
          icon="mdi-magnify"
          @click="onclick"
        />
      </template>
      <template #resultlist>
        <camera :value="searchValve" :dosearchflg="doSearchFlg" />
        <analysisServer
          :value="searchValve"
          :dosearchflg="doSearchFlg"
          class="mt-8"
        />
      </template>
    </k-crud-page>
  </div>
</template>
<script>
import camera from './camera/camera';
import analysisServer from './analysisServer/analysisServer';

export default {
  components: {
    camera,
    analysisServer,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || '不能为空',
      },
      orgCodes: [],
      items: [],
      searchValve: '',
      doSearchFlg: true,
    };
  },
  computed: {},
  created() {
    //获取树形控件
    this.getTree();
  },
  methods: {
    onclick() {
      // if (this.$refs.tree.treeName) {
      this.searchValve = this.$refs.tree.treeName;
      // }
      this.doSearchFlg = !this.doSearchFlg;
    },
    treeChange(e) {
      const self = this;
      const orgCode = e?.toString();
      if (e.length) {
        searchName(this.items);
      } else {
        self.searchValve = '';
      }

      function searchName(data) {
        if (data.length) {
          data.map((item) => {
            if (item.uuid === orgCode) {
              console.log(self, this);
              self.searchValve = item.name;
            } else if (item?.children?.length) {
              searchName(item.children);
            }
          });
        }
      }
    },
    getTree() {
      this.$api.organization.organization.getOrTree().then(({ code, data }) => {
        if (code === 200) {
          this.items = data;
        }
      });
    },
  },
};
</script>
<style scoped lang='scss'>

</style>
